import { useEffect, useState } from "react";
import "./App.css";
import styled from "styled-components";
import {
  Button,
  TextField,
  NativeSelect,
  Input,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51IdOL5HUpcNhaHiQlaVQViKvOZo2izMMif2BRcVCaurLvxwf0YejquyRLKZVqNXIJGjiMZ8hpIRcPMLHASNyMtQ600yj0ZeauH"
);

const mainServer = "https://api.rfmtools.io/";

const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const SplitDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UserInfoGrid = styled.div`
  max-height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
`;

const UserInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
`;

const UserInfoPurchases = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  background-color: #cecece44;
  padding: 20px;
  margin: 20px;
  overflow-y: auto;
`;

const SplitDivFirst = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const SpanInfo = styled.span`
  text-align: left;
  width: 240px;
  margin: 3px 0px;
`;

const ChartDiv = styled.div`
  height: 500px;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateUserDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export type User = {
  _id: string;
  name: string;
  email: string;
  lastPurchaseDate: Date | null;
  totalExpended: number;
  purchases: Purchase[];
  R: number;
  F: number;
  M: number;
  stripe_id: string;
  convertKit_id: string;
};

export type CheckoutSendServer = {
  line_items: {
    price_data: {
      currency: string;
      product_data: {
        name: string;
      };
      unit_amount: number;
    };
    quantity: number;
  }[];
  client_reference_id: string;
  customer_email?: string;
  customer: string;
};

export type NewUser = {
  name: string;
  email: string;
  lastPurchaseDate: Date | null;
  totalExpended: number;
  purchases: Purchase[];
  R: number;
  F: number;
  M: number;
};

export type Purchase = {
  value: number;
  createdAt: Date;
};

export type NewPurchase = {
  _id: string;
  value: number;
  when: number;
};

export type Data = {
  key: string;
  "1": number;
  "2": number;
  "3": number;
  "4": number;
  "5": number;
};

export type TooltipComponent = {
  color: string;
  height: number;
  id: string;
  labelTextColor: string;
  opacity: number;
  value: number;
  width: number;
  x: number;
  xKey: string;
  y: number;
  yKey: "R" | "F" | "M";
};

function App() {
  const [value, setValue] = useState<string>("0");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [allUser, setAllUser] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const [chartData, setChartData] = useState<Data[]>([]);

  const getUsers = async () => {
    let response = await fetch(mainServer + "user", {
      method: "GET",
    });
    let allUsersLoad: User[] = await response.json();
    console.log(allUsersLoad);
    setAllUser(allUsersLoad);
    setChartData(dataHandlerGenerator(allUsersLoad));
    if (currentUser) {
      let selectedUser = allUsersLoad.find(
        (user) => user._id == currentUser._id
      );
      setCurrentUser(selectedUser);
    }
  };

  const dataHandlerGenerator = (allUsersInfo: User[]): Data[] => {
    let rArray: number[] = Array(5).fill(0);
    let fArray: number[] = Array(5).fill(0);
    let mArray: number[] = Array(5).fill(0);
    allUsersInfo.map((userInfo) => {
      if (userInfo.R == 0 || userInfo.F == 0 || userInfo.M == 0) return;
      rArray[userInfo.R - 1] += 1;
      fArray[userInfo.F - 1] += 1;
      mArray[userInfo.M - 1] += 1;
    });
    return [
      {
        key: "R",
        "1": rArray[0],
        "2": rArray[1],
        "3": rArray[2],
        "4": rArray[3],
        "5": rArray[4],
      },
      {
        key: "F",
        "1": fArray[0],
        "2": fArray[1],
        "3": fArray[2],
        "4": fArray[3],
        "5": fArray[4],
      },
      {
        key: "M",
        "1": mArray[0],
        "2": mArray[1],
        "3": mArray[2],
        "4": mArray[3],
        "5": mArray[4],
      },
    ];
  };

  const newUser = async () => {
    if (name == "") return alert("Name has no letters.");
    if (
      email == "" &&
      !email.includes("@") &&
      email.split("@").length != 2 &&
      !email.split("@")[1].includes(".")
    )
      return alert("Invalid email.");
    let newUser: NewUser = {
      name: name,
      email: email,
      lastPurchaseDate: null,
      totalExpended: 0,
      purchases: [],
      R: 0,
      F: 0,
      M: 0,
    };
    try {
      let response = await fetch(mainServer + "user", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser),
      });
    } catch (err) {
      console.log(err);
    }
    setName("");
    setEmail("");
    await getUsers();
  };

  const purchase = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number,
    name: string
  ) => {
    if (currentUser == undefined) return alert("No user select");
    const stripe = await stripePromise;
    let newBuy: CheckoutSendServer = {
      line_items: [
        {
          price_data: {
            currency: "usd",
            product_data: {
              name,
            },
            unit_amount: value,
          },
          quantity: 1,
        },
      ],
      client_reference_id: currentUser._id,
      customer: currentUser.stripe_id,
    };
    if (stripe)
      try {
        let response = await fetch(mainServer + "payment-checkout", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newBuy),
        });
        const session = await response.json();
        console.log(response, session);
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        console.log(result);
      } catch (err) {
        console.log(err);
      }
    else alert("Stripe error");
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <MainDiv>
      <SplitDivFirst>
        <CreateUserDiv>
          <TextField
            type="text"
            label="User name:"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></TextField>
          <TextField
            type="email"
            label="User e-mail:"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
          <Button variant="outlined" onClick={() => newUser()}>
            Create User
          </Button>
        </CreateUserDiv>
        <UserInfoGrid>
          <UserInfoDiv>
            <span>Selected User</span>
            <NativeSelect
              style={{ width: 200 }}
              onChange={(e) => {
                if (e.target.value) {
                  let selectedUser = allUser.find(
                    (user) => user._id == e.target.value
                  );
                  setCurrentUser(selectedUser);
                }
              }}
              value={currentUser?._id}
            >
              <option value={undefined}>No user selected</option>
              {allUser.map((user) => {
                return <option value={user._id}>{user.name}</option>;
              })}
            </NativeSelect>
            <SpanInfo>R = {currentUser?.R}</SpanInfo>
            <SpanInfo>F = {currentUser?.F}</SpanInfo>
            <SpanInfo>M = {currentUser?.M}</SpanInfo>
            <SpanInfo>
              LastPurchase ={" "}
              {currentUser
                ? moment(currentUser.lastPurchaseDate).format("LL")
                : ""}
            </SpanInfo>
            <SpanInfo>TotalExpended = $ {currentUser?.totalExpended}</SpanInfo>
          </UserInfoDiv>
          <UserInfoPurchases>
            <span>Purchases:</span>
            {currentUser?.purchases.map((purchase) => {
              return (
                <SpanInfo>
                  {moment(purchase.createdAt).format("LL")}: $ {purchase.value}
                </SpanInfo>
              );
            })}
          </UserInfoPurchases>
        </UserInfoGrid>
        <ChartDiv>
          <ResponsiveHeatMap
            data={chartData}
            keys={["1", "2", "3", "4", "5"]}
            indexBy="key"
            margin={{ top: 30, right: 40, bottom: 30, left: 40 }}
            forceSquare={true}
            axisTop={{
              orient: "top",
              tickSize: 5,
              tickPadding: 5,
              legend: "",
              legendOffset: 36,
            }}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
            }}
            tooltip={(e: TooltipComponent) => {
              let users = allUser
                .map((user) => {
                  if (user[e.yKey] == parseInt(e.xKey)) return user.name;
                })
                .filter((value) => value != undefined);
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Users on this cell:
                  </span>
                  {users.map((user) => (
                    <span>{user}</span>
                  ))}
                </div>
              );
            }}
            cellOpacity={1}
            cellBorderColor={{ from: "color", modifiers: [["darker", 0.4]] }}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.8]] }}
            animate={true}
            motionStiffness={80}
            motionDamping={9}
            hoverTarget="cell"
            cellHoverOthersOpacity={0.25}
          />
        </ChartDiv>
      </SplitDivFirst>
      <SplitDiv>
        <FormControl>
          <InputLabel htmlFor="price">Price</InputLabel>
          <Input
            id="price"
            type="number"
            startAdornment="$"
            value={value}
            onChange={(e) => {
              let insertedValue = e.target.value.split(".");
              if (e.target.value.includes(".")) {
                if (insertedValue?.length <= 2 && insertedValue[1].length <= 2)
                  setValue(e.target.value);
              } else setValue(e.target.value);
            }}
          />
        </FormControl>
        <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value) * 100, "Product 1")}
        >
          Buy Now
        </Button>
        {/* <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value), 30)}
        >
          Buy 30 days ago
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value), 60)}
        >
          Buy 60 days ago
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value), 90)}
        >
          Buy 90 days ago
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value), 180)}
        >
          Buy 180 days ago
        </Button>
        <Button
          variant="outlined"
          onClick={(e) => purchase(e, parseFloat(value), 365)}
        >
          Buy 1 year ago
        </Button> */}
      </SplitDiv>
    </MainDiv>
  );
}

export default App;
